import Vue from "vue";
import Options from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");

const storedUser = localStorage.getItem("user");
let user = JSON.parse(storedUser ? storedUser : "");

@Options({
  name: "SidebarComponent",
})
export default class Sidebar extends Vue {
  public appName = process.env.VUE_APP_NAME;
  public isShow = false;
  public client = user;
  userType = ""

  public user = storedUser ? JSON.parse(storedUser) : null;

  @Auth.Action
  private signOut!: () => void;

  @Auth.Getter
  private collapseable!: boolean;

  ///Use for toggle side bar
  @Watch("collapseable", { deep: true })
  onPropertyChanged(value: string, oldValue: string) {
    this.isShow = this.collapseable;
  }

  logOut() {
    this.signOut();
    this.$router.push("/");
  }
}
