export default function auhtHeader(key:string = "") {

  const storedUser = localStorage.getItem("user");
  let user = JSON.parse(storedUser ? storedUser : "");

  if (key.length == 0)
  {
    key = "xxxx2589"
  }

  if (user && user.token) {
    //console.log({ Authorization: 'Bearer ' + user.token, "Content-type": "application/json","Access-Control-Allow-Origin": "*" })
    return {
      Authorization: "Bearer " + user.token,
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "tenantId" : key
    }; // for Spring Boot back-end
    //return { 'x-access-token': user.accessToken };       // for Node.js Express back-end
  } else {
    return {};
  }
}
