import Vue from "vue";
import Options from "vue-class-component";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");
import Multiselect from "vue-multiselect";
import { Watch } from "vue-property-decorator";
const storedUser = localStorage.getItem("user");
@Options({
  name: "NavbarComponent",
  components: {
    Multiselect,
  },
})
export default class Navbar extends Vue {
  private isShow = false;
  sites: any = [];
  bId: any = {};

  public user = storedUser ? JSON.parse(storedUser) : null;

  @Auth.State("user")
  private currentUser!: any;

  @Auth.State("status")
  private collapse!: any;
  isload: boolean = false;

  @Auth.Action
  private signOut!: () => void;

  @Auth.Mutation
  private setCollapseable!: (newValue: boolean) => void;

  @Auth.Mutation
  private setBranchId!: (newValue: string) => void;

  @Auth.Getter
  private getCurrentUser!: any;

  @Auth.Getter
  private getBranchId!: string;

  created() {
    this.sites = this.user.sites;
    console.log("Call from created " + this.getBranchId)
    if (this.sites !== undefined && this.getBranchId == "") {
      this.bId = this.sites[0];
      this.setBranchId(this.bId.siteKey);
    }
    else{
      var siteKey = this.getBranchId
      var arrR = this.sites.filter(function(ele){
        return (ele.siteKey == siteKey)
     });
      this.bId = arrR
    }

    this.isload = true;
  }

  @Watch("getBranchId", { deep: true })
  async onBranchPropertyChanged(value: string, oldValue: string) {

    this.sites = this.user.sites;

    if (this.getBranchId !== undefined) {
      console.log("Call from onBranchPropertyChanged " + this.getBranchId)
      //this.bId = this.sites[0];
     // this.bId.siteKey = this.getBranchId;
    }

  }

  logOut() {
    this.signOut();
    this.$router.push("/");
  }

  onChange(option) {
    if (option != null)
    {
      console.log(option.siteKey)
      this.setBranchId(option.siteKey);
    }
  }

  toggleSidebar() {
    this.isShow = !this.isShow;
    this.setCollapseable(this.isShow);
    //this.$store.commit("isCollapseChange", this.isShow);
  }
}
